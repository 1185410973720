.row {
  display: flex;
}

.column {
  display: flex;
  flex-direction: column;
}

.space-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: flex-end;
}

.align-center {
  align-items: center;
}

.position-relative {
  position: relative;
}

.section-title {
  font-size: 14px;
  color: #2b80ff;
  font-weight: bold;
}

.section-text {
  font-size: 14px;
  font-weight: 400;
  color: #5a607f;
  word-break: break-word;
}

.input-name {
  font-size: 12px;
  color: #131523;
}

.blue {
  color: #426da9;
}

.input {
  width: 100%;
  height: 40px;
  background: white;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  outline: 0;
  padding: 4px 11px 4px;
  margin-top: 0.5em;
  font-size: 12px;
}

.input:focus {
  border-color: #426da9;
  box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
  border-right-width: 1px;
}

.error-message {
  position: absolute;
  color: red;
  font-size: 12px;
  margin: 3.5px 0 0 0;
}

.change-status-button {
  padding: 0;
  font-size: 12px;
  font-weight: 600;
  color: #426da9;
}

.action-buttons-margin {
  margin: 35px 0 0 0;
}

.action-button {
  width: 84px;
  height: 34px;
  border-radius: 3px;
  font-size: 12px;
}

.action-button-save {
  border: 1px solid #426da9;
  background: #dde4eb;
}

.action-button-save:hover {
  background: #426da9;
  color: white !important;
  border: none;
}

.action-button-cancel {
  border: 1px solid #bfccda;
}

.action-button-cancel:hover {
  background: #dde4eb;
  border: none;
  color: black !important;
}

.sale-history-text {
  font-size: 12px;
}

.status-date-history {
  padding-right: 25.5px;
  padding-left: 17px;
}

.status-description-history {
  font-size: 12px;
  padding-right: 30px;
}

.sale-user-history {
  color: #426da9;
  padding-right: 28px;
}

.dot {
  border-radius: 100%;
  margin-right: 10px;
  padding: 0 8px 0;
}

.unattended {
  background-color: #1659e7;
}

.scheduled {
  background-color: #F2A900;
}

.installed {
  background-color: #58E933;
}

.cancelled {
  background-color: #f53c56;
}

@media screen and (min-width: 1280px) {

  .section-title,
  .section-text {
    font-size: 14px;
  }

  .input-name,
  .input,
  .error-message,
  .change-status-button,
  .action-button,
  .sale-history-text,
  .status-description-history {
    font-size: 12px;
  }

  .action-button {
    width: 84px;
    height: 34px;
  }
}

@media screen and (min-width: 1366px) {

  .section-title,
  .section-text {
    font-size: 15.75px;
  }

  .input-name,
  .input,
  .error-message,
  .change-status-button,
  .action-button,
  .sale-history-text,
  .status-description-history {
    font-size: 13.5px;
  }

  .action-button {
    width: 94.5px;
    height: 38.25px;
  }
}

@media screen and (min-width: 1920px) {

  .section-title,
  .section-text {
    font-size: 17.71px;
  }

  .input-name,
  .input,
  .error-message,
  .change-status-button,
  .action-button,
  .sale-history-text,
  .status-description-history {
    font-size: 15.18px;
  }

  .action-button {
    width: 106.31px;
    height: 43.03px;
  }
}