.spinner {
  font-size: 35px !important;
  color: #426da9;
}

@media screen and (min-width: 1280px) {
  .spinner {
    font-size: 35px !important;
  }
}

@media screen and (min-width: 1366px) {
  .spinner {
    font-size: 39.37px !important;
  }
}

@media screen and (min-width: 1920px) {
  .spinner {
    font-size: 44.29px !important;
  }
}