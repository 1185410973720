.row {
  display: flex;
}

.column {
  display: flex;
  flex-flow: column;
}

.subheader-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 0 31px 0 27px;
}

.subheader-title {
  color: #5a607f;
  font-size: 14px;
}

.form-button {
  height: 34px;
  background-color: #1d428a;
  border-radius: 3px;
  color: #ffffff;
  font-size: 12px;
  display: flex;
  align-items: center;
  font-weight: 400;
  border: none;
}

.form-button:hover {
  background-color: #426da9;
  color: white !important;
  border: none;
}

.form-button-icon {
  color: #ffffff;
}

.form-button-icon:hover {
  color: white;
}

.pagination-container {
  margin: 15px 0;
  padding: 0 31px 0 27px;
}

.pagination {
  font-size: 12px;
  color: #001737;
}

.export-button {
  cursor: pointer;
  font-size: 14px;
  color: #2b80ff;
  font-weight: bold;

  .export-button-icon {
    margin: 0 10px 0 0;
  }
}

@media screen and (min-width: 1280px) {

  .subheader-title,
  .export-button {
    font-size: 14px;
  }

  .form-button,
  .pagination {
    font-size: 12px;
  }
}

@media screen and (min-width: 1366px) {

  .subheader-title,
  .export-button {
    font-size: 15.75px;
  }

  .form-button,
  .pagination {
    font-size: 13.5px;
  }
}

@media screen and (min-width: 1920px) {

  .subheader-title,
  .export-button {
    font-size: 17.71px;
  }

  .form-button,
  .pagination {
    font-size: 15.18px;
  }
}