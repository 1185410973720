.row {
  display: flex;
}

.column {
  display: flex;
  flex-flow: column;
}

.subheader-title {
  color: #5a607f;
  font-size: 14px;
}

.date-picker {
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  height: 38px;
  width: 200px;
  margin-right: 15px;
}

.pagination-container {
  margin: 15px 0;
  padding: 0 31px 0 27px;
}

.pagination {
  font-size: 12px;
  color: #001737;
}

.export-button {
  cursor: pointer;
  font-size: 14px;
  color: #2b80ff;
  font-weight: bold;

  .export-button-icon {
    margin: 0 10px 0 0;
  }
}

@media screen and (min-width: 1280px) {

  .subheader-title,
  .export-button {
    font-size: 14px;
  }

  .date-picker {
    width: 200px;
  }

  .pagination {
    font-size: 12px;
  }
}

@media screen and (min-width: 1366px) {

  .subheader-title,
  .export-button {
    font-size: 15.75px;
  }

  .date-picker {
    width: 225px;
  }

  .pagination {
    font-size: 13.5px;
  }
}

@media screen and (min-width: 1366px) {

  .subheader-title,
  .export-button {
    font-size: 17.71px;
  }

  .date-picker {
    width: 253.12px;
  }

  .pagination {
    font-size: 15.18px;
  }
}