.row {
  display: flex;
}

.column {
  display: flex;
  flex-flow: column;
}

.align-center {
  align-items: center;
}

.position-end {
  margin-left: auto;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.header-title {
  color: #001737;
  font-size: 24px;
  font-weight: bold;
}

.header-breadcrumb {
  font-size: 12px;
  color: #001737;
}

.create-sale-button {
  background-color: #1d428a;
  height: 34px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #ffffff;
  border: none;
}

.create-sale-button:hover {
  background-color: #426da9;
  color: #ffffff !important;
  border: none;
}

.create-sale-icon {
  color: #ffffff;
}

.create-sale-icon:hover {
  color: #ffffff;
}

.table-container {
  display: flex;
  flex-direction: column;
  padding: 24px 0 40px;
}

.filters-title {
  padding: 0 0 10px 0;
  font-size: 14px;
  font-weight: bold;
  color: #2b80ff;
}

.select {
  width: 150px;
  margin-right: 15px;
}

.select-compass {
  width: 93px;
  margin-right: 15px;
}

.select-channel {
  width: 93px;
  margin-right: 15px;
}

.date-picker {
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  height: 38px;
  width: 200px;
}

.search-input {
  width: 185px;
}

.pagination-container {
  margin: 15px 0;
  padding: 0 40px;
}

.pagination {
  font-size: 12px;
  color: #001737;
}

.export-button {
  cursor: pointer;
  font-size: 14px;
  color: #2b80ff;
  font-weight: bold;

  .export-button-icon {
    margin: 0 10px 0 0;
  }
}

@media screen and (min-width: 1280px) {
  .header-title {
    font-size: 24px;
  }

  .header-breadcrumb,
  .create-sale-button,
  .pagination {
    font-size: 12px
  }

  .filters-title,
  .export-button {
    font-size: 14px
  }

  .select {
    width: 150px;
  }

  .select-compass,
  .select-channel {
    width: 93px;
  }

  .date-picker {
    width: 200px;
  }

  .search-input {
    width: 185px;
  }
}

@media screen and (min-width: 1366px) {
  .header-title {
    font-size: 27px;
  }

  .header-breadcrumb,
  .create-sale-button,
  .pagination {
    font-size: 13.5px;
  }

  .filters-title,
  .export-button {
    font-size: 15.75px;
  }

  .select {
    width: 168.75px;
  }

  .select-compass,
  .select-channel {
    width: 104.62px;
  }

  .date-picker {
    width: 225px;
  }

  .search-input {
    width: 208.12px;
  }
}

@media screen and (min-width: 1920px) {
  .header-title {
    font-size: 30.37px;
  }

  .header-breadcrumb,
  .create-sale-button,
  .pagination {
    font-size: 15.18px;
  }

  .filters-title,
  .export-button {
    font-size: 17.71px;
  }

  .select {
    width: 189.84px;
  }

  .select-compass,
  .select-channel {
    width: 117.69px;
  }

  .date-picker {
    width: 253.12px;
  }

  .search-input {
    width: 234.13px;
  }
}