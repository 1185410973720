.position-relative {
  position: relative;
}

.login-container {
  background-color: #f3f3f3;
  position: absolute;
  width: 100%;
  top: 0px;
  right: 0;
  height: 100vh;
  display: flex;
  flex-direction: row;
}

.left-content {
  width: 50vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  background-image: url("../assets/images/LoginSistema_Oraculo_03-02.png"), linear-gradient(#1d4289, #0e2441);
  background-repeat: no-repeat;
  background-size: cover;
}

.logo {
  width: 550px;
  margin-bottom: 3em;
}

.circle {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  position: relative;
}

.line {
  width: 3px;
  height: 300px;
  position: absolute;
  bottom: -320px;
  background: #5c92ff40;
}

.circle img {
  width: 80px;
  height: 80px;
  border-radius: 100%;
  object-fit: contain;
}

.text-content {
  width: 400px;
  height: auto;
  margin-bottom: 3em;
  margin-top: 3em;
}

.text-content h3 {
  color: white;
  font-size: 38px;
  padding: 0;
  margin: 0;
}

.text-content b {
  color: white;
  font-size: 45px;
  padding: 0;
  margin: 0;
}

.text-content p {
  color: white;
  padding: 0;
  margin: 0;
  font-size: 12px;
  text-align: justify;
  text-justify: inter-word;
}

.right-content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.right-container {
  width: 418px;
  height: auto;
  margin-left: 0;
  padding: 0 0;
  display: flex;
  justify-content: center;
  flex-flow: column;
}

.main-title {
  font-size: 25px;
}

.subtitle {
  font-size: 12px;
  color: #5a607f;
}

.input-title {
  font-size: 12px;
  color: #131523;
}

.right-container pre {
  display: inline;
  color: #2b80ff;
  font-size: 12px;
}

.input {
  height: 40px;
  margin-top: 8px;
  font-size: 12px;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
}

.error-message {
  position: absolute;
  color: red;
  font-size: 12px;
  margin: 3.5px 0 0 0;
}

.button {
  height: 34px;
  color: white;
  width: 100%;
  border-radius: 3px;
  background-color: #426DA9;
  font-size: 12px;
}

.button:hover {
  border: none;
  background-color: #2a5796;
  color: white !important;
}

@media screen and (min-width: 1280px) {
  .text-content h3 {
    font-size: 38px;
  }

  .text-content b {
    font-size: 45px;
  }

  .text-content p,
  .subtitle,
  .input-title,
  .right-container pre,
  .input,
  .error-message,
  .button {
    font-size: 12px;
  }

  .right-container {
    width: 418px;
  }

  .main-title {
    font-size: 25px;
  }

  .button {
    height: 34px;
  }
}

@media screen and (min-width: 1366px) {
  .text-content h3 {
    font-size: 42.75px;
  }

  .text-content b {
    font-size: 50.62px;
  }

  .text-content p,
  .subtitle,
  .input-title,
  .right-container pre,
  .input,
  .error-message,
  .button {
    font-size: 13.5px;
  }

  .right-container {
    width: 470.25px;
  }

  .main-title {
    font-size: 28.12px;
  }

  .button {
    height: 38.25px;
  }
}

@media screen and (min-width: 1920px) {
  .text-content h3 {
    font-size: 48.09px;
  }

  .text-content b {
    font-size: 56.94px;
  }

  .text-content p,
  .subtitle,
  .input-title,
  .right-container pre,
  .input,
  .error-message,
  .button {
    font-size: 15.18px;
  }

  .right-container {
    width: 529.03px;
  }

  .main-title {
    font-size: 31.63px;
  }

  .button {
    height: 43.03px;
  }
}