.row {
  display: flex;
}

.column {
  display: flex;
  flex-direction: column;
}

.flex {
  flex: 1
}

.space-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: flex-end;
}

.align-center {
  align-items: center;
}

.position-relative {
  position: relative;
}

.blue {
  color: #426da9
}

.section-container {
  background-color: white;
  display: flex;
  flex-flow: column;
  padding: 0 31px 0 27px;
}

.section-title {
  font-size: 14px;
  color: #5a607f
}

.column-left {
  width: 60%;
  background-color: #f6f6f6;
  border: 1px solid #dde4eb;
  padding: 25px 25px 94px 15px;
  margin-top: 30px
}

.column-right {
  padding: 25px 20px 90px 25px;
  margin-top: 30px
}

.form-section-title {
  font-size: 14px;
  font-weight: bold;
  color: #2b80ff;
}

.form-input-name {
  font-size: 12px;
  color: #131523;
}

.important-info-title {
  font-size: 12px;
  font-weight: bold;
  color: #2b80ff;
}

.important-info-paragraph {
  font-size: 10px;
  color: #959595;
}

.info-title {
  font-size: 12px;
  font-weight: bold;
  color: #131523;
}

.info-text {
  font-size: 12px;
  color: #868d96;
}

.dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  margin: 0 10px 0 0;
}

.facebook {
  background-color: #3b5998;
}

.gmail {
  background-color: #f53c56;
}

.sms {
  background-color: #26a805;
}

.switch-container {
  width: 20%;
  margin-left: 5%;
}

.switch-text {
  font-size: 12px;
  color: #131523;
  align-self: center;
}

.button-modify {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 101px;
  height: 34px;
  font-size: 12px;
  border-radius: 3px;
  font-weight: 400;
}

.form-input {
  width: 100%;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  outline: 0;
  padding: 4px 11px 4px;
  margin-top: 0.5em;
  font-size: 12px;
}

.form-input:focus {
  border-color: #426da9;
  box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
  border-right-width: 1px;
}

.error-message {
  position: absolute;
  color: red;
  font-size: 12px;
  margin: 3.5px 0 0 0;
}

.action-button {
  width: 84px;
  height: 34px;
  border-radius: 3px;
  color: #001737;
  font-size: 12px;
}

.action-button:hover {
  color: #426da9;
  border: none;
}

.action-button-save {
  border: 1px solid #426da9;
  background: #dde4eb;
}

.action-button-cancel {
  border: 1px solid #bfccda;
}

@media screen and (min-width: 1280px) {

  .section-title,
  .form-section-title {
    font-size: 14px;
  }

  .button-modify {
    width: 101px;
    height: 34px;
  }

  .column-left {
    width: 55%;
  }

  .form-input-name,
  .important-info-title,
  .info-title,
  .info-text,
  .switch-text,
  .button-modify,
  .form-input,
  .error-message,
  .action-button {
    font-size: 12px;
  }

  .important-info-paragraph {
    font-size: 10px;
  }

  .dot {
    width: 10px;
    height: 10px;
  }

  .action-button {
    width: 84px;
    height: 34px;
  }
}

@media screen and (min-width: 1366px) {

  .section-title,
  .form-section-title {
    font-size: 15.75px;
  }

  .button-modify {
    width: 113.62px;
    height: 38.25px;
  }

  .column-left {
    width: 55%;
  }

  .form-input-name,
  .important-info-title,
  .info-title,
  .info-text,
  .switch-text,
  .button-modify,
  .form-input,
  .error-message,
  .action-button {
    font-size: 13.5px;
  }

  .important-info-paragraph {
    font-size: 11.25px;
  }

  .dot {
    width: 11.25px;
    height: 11.25px;
  }

  .action-button {
    width: 94.5px;
    height: 38.25px;
  }
}

@media screen and (min-width: 1920px) {

  .section-title,
  .form-section-title {
    font-size: 17.71px;
  }

  .button-modify {
    width: 127.82px;
    height: 43.03px;
  }

  .form-input-name,
  .important-info-title,
  .info-title,
  .info-text,
  .switch-text,
  .button-modify,
  .form-input,
  .error-message,
  .action-button {
    font-size: 15.18px;
  }

  .switch-container {
    width: 15%;
  }

  .important-info-paragraph {
    font-size: 12.65px;
  }

  .dot {
    width: 12.65px;
    height: 12.65px;
  }

  .action-button {
    width: 106.31px;
    height: 40.03px;
  }
}