.row {
  display: flex;
}

.column {
  display: flex;
  flex-flow: column;
}

.align-center {
  align-items: center;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.header-title {
  color: #001737;
  font-size: 24px;
  font-weight: bold;
}

.tooltip-subtitle {
  color: #172b4d;
  font-size: 12px;
  margin: 0 0 0 5px;
}

.tooltip-dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  margin: 0 3px 0 5px;
}

.header-breadcrumb {
  font-size: 12px;
  color: #001737;
}

.table-container {
  display: flex;
  flex-direction: column;
  padding: 24px 0 40px;
}

.filters-title {
  padding: 0 0 10px 0;
  font-size: 14px;
  font-weight: bold;
  color: #2b80ff;
}

.date-picker {
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  height: 38px;
  width: 200px;
}

.pagination-container {
  margin: 15px 0;
  padding: 0 40px;
}

.pagination {
  font-size: 12px;
  color: #001737;
}

.export-button {
  cursor: pointer;
  font-size: 14px;
  color: #2b80ff;
  font-weight: bold;

  .export-button-icon {
    margin: 0 10px 0 0;
  }
}

@media screen and (min-width: 1280px) {
  .header-title {
    font-size: 24px;
  }

  .tooltip-subtitle,
  .header-breadcrumb,
  .pagination {
    font-size: 12px;
  }

  .tooltip-dot {
    height: 10px;
    width: 10px;
  }

  .filters-title,
  .export-button {
    font-size: 14px;
  }

  .date-picker {
    width: 200px;
  }
}

@media screen and (min-width: 1366px) {
  .header-title {
    font-size: 27px;
  }

  .tooltip-subtitle,
  .header-breadcrumb,
  .pagination {
    font-size: 13.5px;
  }

  .tooltip-dot {
    height: 11.25px;
    width: 11.25px;
  }

  .filters-title,
  .export-button {
    font-size: 15.75px;
  }

  .date-picker {
    width: 225px;
  }
}

@media screen and (min-width: 1920px) {
  .header-title {
    font-size: 30.37px;
  }

  .tooltip-subtitle,
  .header-breadcrumb,
  .pagination {
    font-size: 15.18px;
  }

  .tooltip-dot {
    height: 12.65px;
    width: 12.65px;
  }

  .filters-title,
  .export-button {
    font-size: 17.71px;
  }

  .date-picker {
    width: 253.12px;
  }
}