.custom-scroll {
  &::-webkit-scrollbar {
    width: 10px;
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.1);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.2);
  }

  &::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.4);
  }

  &::-webkit-scrollbar-thumb:active {
    background: rgba(0, 0, 0, 0.9);
  }
}

.row {
  display: flex;
}

.column {
  display: flex;
  flex-direction: column;
}

.space-between {
  justify-content: space-between;
}

.align-center {
  align-items: center;
}

.align-baseline {
  align-items: baseline;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: flex-end;
}

.flex {
  flex: 1;
}

.position-relative {
  position: relative;
}

.form-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  background: white;
  padding: 5.6% 5.5% 5.6% 5.2%;
  overflow-y: scroll;
}

.form-title {
  color: #5a607f;
  font-size: 20px;
  font-weight: bold;
}

.close-form-icon {
  font-size: 14px;
  color: #959595;
}

.form-pagination {
  font-size: 14px;
  color: #172b4d;
  margin-right: 10px;
  margin-left: 10px;
}

.form-pagination-icon {
  font-size: 14px;
  color: #172b4d;
}

.checked-form-pagination-icon {
  font-size: 14px;
  color: #8FD14F;
}

.form-section-title {
  font-size: 14px;
  font-weight: bold;
  color: #2b80ff;
}

.form-input-name {
  font-size: 12px;
  color: #131523;
}

.blue {
  color: #426da9;
}

.form-input {
  width: 100%;
  height: 40px;
  background: white;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  outline: 0;
  padding: 4px 11px 4px;
  margin-top: 0.5em;
  font-size: 12px;
}

.form-input:focus {
  border-color: #426da9;
  box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
  border-right-width: 1px;
}

.error-message {
  position: absolute;
  color: red;
  font-size: 12px;
  margin: 3.5px 0 0 0;
}

.search-container {
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 5px 0 0 0;
  padding: 10px;
  position: absolute;
  z-index: 999;
  top: 0;
  left: 0;
}

.searched-address {
  display: flex;
  color: #2b80ff;
  align-items: center;
  font-size: 12px;
}

.searched-address:hover {
  color: #69b1ff;
  background-color: lightgray;
}

.selected-address {
  background-color: #2b80ff;
  color: #ffffff;
}

.form-textarea {
  width: 100%;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  outline: 0;
  padding: 4px 11px 4px;
  margin-top: 0.5em;
  font-size: 12px;
}

.form-textarea:focus {
  border-color: #426da9;
  box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
  border-right-width: 1px;
}

.example-text {
  font-size: 12px;
  font-weight: 400;
  color: #acacac;
}

.fixed {
  margin: auto 0 0;
}

.float {
  margin: 0 0 0 auto;
  position: relative;
  z-index: 1000;
  top: 75%;
  padding: 0 2em 0 0;
  width: 0;
}

.input-float {
  z-index: 1000;
  position: relative;
  padding: 2em 3em 0 2em;
}

.form-action-button {
  width: 84px;
  height: 34px;
  border-radius: 3px;
  font-size: 12px;
}

.form-action-button-save {
  border: 1px solid #426da9;
  background: #dde4eb;
}

.form-action-button-save:hover {
  background: #426da9;
  color: white !important;
  border: none;
}

.form-action-button-cancel {
  border: 1px solid #bfccda;
  background-color: #ffffff;
}

.form-action-button-cancel:hover {
  background: #dde4eb;
  border: none;
  color: black !important;
}

.image-container {
  object-fit: contain;
  background-color: #bababa;
  border-radius: 5px;
  margin-right: 1em;
}

.plan-name-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100px;
}

.plan-name {
  color: #161d6f;
  font-size: 14px;
  font-weight: bold;
}

.plan-tech {
  color: #2b80ff;
  font-size: 12px;
  font-weight: bold;
}

.plan-type {
  font-size: 12px;
  color: #435464;
}

.plan-price-container {
  width: 32%;
}

.plan-price {
  background-color: #dde4eb;
  border-radius: 5px;
  width: 100%;
  height: 43px;
}

.price {
  color: #172b4d;
  font-size: 14px;
  font-weight: bold;
}

.installation-price {
  color: #172b4d;
  font-size: 10px;
}

.plan-description-container {
  width: 62%;
}

.plan-description {
  font-size: 14px;
  color: #5a607f;
  word-break: break-word;
  margin: 20px 0 15px;
  line-height: 1.2;
}

.characteristics-icon {
  color: #bcbcbc;
}

.internet-characteristics {
  color: #bcbcbc;
  font-size: 14px;
  margin: 0 5px 0 5px;
}

.sd-channels {
  color: #bcbcbc;
  font-size: 14px;
}

.hd-channels {
  color: #bcbcbc;
  font-size: 14px;
  margin-left: 10px;
}

.telephone {
  color: #bcbcbc;
  font-size: 14px;
}

.promotion-container {
  padding: 20px;
  background-color: #ffffff;
  margin: 20px;
  display: flex;
  border-radius: 5px;
}

.promotion-tag {
  width: 122px;
  background-color: #2b80ff;
  align-items: center;
  display: flex;
  justify-content: center;
  height: 35px;
  border-radius: 0px 5px 5px 0px;
  font-size: 14px;
  color: white;
}

.promotion-name {
  font-size: 14px;
  color: #161d6f;
  font-weight: bold;
}

.promotion-price {
  background-color: #f5f5f5;
  border-radius: 5px;
  padding: 10px;
  margin: 10px 10px 0 0;
}

.promotion-discount-type {
  font-size: 12px;
  color: #435464;
  margin-bottom: 5px;
}

.promotion-discount {
  font-size: 12px;
  font-weight: bold;
  color: #131523;
}

.promotion-type-text {
  font-size: 10px;
  color: #131523;
  margin: 0 0 5px;
}

.promotion-period {
  font-size: 11px;
  font-weight: 700;
  color: #435464;
  font-family: "Roboto", sans-serif;
}

.promotion-description {
  font-size: 11px;
  font-weight: 300;
  color: #131523;
  opacity: 0.6;
  letter-spacing: 0;
}

.address-name {
  font-size: 12px;
  color: #7f7f7f;
}

.plan-detail {
  font-size: 14px;
  color: #131523;
}

.view-more-button {
  padding: 0;
  height: auto;
  font-size: 12px;
  font-weight: bold;
  color: #2b80ff;

  .view-more-text {
    text-decoration: underline;
  }
}

.view-more-button:hover {
  color: #2b80ff;
}

.switch-text {
  font-size: 12px;
  color: #131523;
}

.switch-margin {
  margin: 0 15px 0;
}

@media screen and (min-width: 1280px) {
  .form-title {
    font-size: 20px;
  }

  .plan-name-ellipsis {
    width: 100px;
  }

  .close-form-icon,
  .form-pagination,
  .form-pagination-icon,
  .checked-form-pagination-icon,
  .form-section-title,
  .plan-name,
  .price,
  .plan-description,
  .internet-characteristics,
  .sd-channels,
  .hd-channels,
  .telephone,
  .promotion-tag,
  .promotion-name,
  .plan-detail {
    font-size: 14px;
  }

  .form-input-name,
  .form-input,
  .error-message,
  .searched-address,
  .form-textarea,
  .example-text,
  .form-action-button,
  .plan-tech,
  .plan-type,
  .promotion-discount-type,
  .promotion-discount,
  .address-name,
  .view-more-button,
  .switch-text {
    font-size: 12px;
  }

  .form-action-button {
    width: 84px;
    height: 34px;
  }

  .plan-price-container {
    width: 32%;
  }

  .plan-price {
    height: 43px;
  }

  .plan-description-container {
    width: 62%;
  }

  .installation-price,
  .promotion-type-text {
    font-size: 10px;
  }

  .promotion-tag {
    width: 122px;
  }

  .promotion-period,
  .promotion-description {
    font-size: 11px;
  }
}

@media screen and (min-width: 1366px) {
  .form-title {
    font-size: 22.5px;
  }

  .plan-name-ellipsis {
    width: 130px;
  }

  .close-form-icon,
  .form-pagination,
  .form-pagination-icon,
  .checked-form-pagination-icon,
  .form-section-title,
  .plan-name,
  .price,
  .plan-description,
  .internet-characteristics,
  .sd-channels,
  .hd-channels,
  .telephone,
  .promotion-tag,
  .promotion-name,
  .plan-detail {
    font-size: 15.75px;
  }

  .form-input-name,
  .form-input,
  .error-message,
  .searched-address,
  .form-textarea,
  .example-text,
  .form-action-button,
  .plan-tech,
  .plan-type,
  .promotion-discount-type,
  .promotion-discount,
  .address-name,
  .view-more-button,
  .switch-text {
    font-size: 13.5px;
  }

  .form-action-button {
    width: 94.5px;
    height: 38.25px;
  }

  .plan-price-container {
    width: 34%;
  }

  .plan-price {
    height: 48.37px;
  }

  .plan-description-container {
    width: 65%;
  }

  .installation-price,
  .promotion-type-text {
    font-size: 11.25px;
  }

  .promotion-tag {
    width: 137.25px;
  }

  .promotion-period,
  .promotion-description {
    font-size: 12.37px;
  }
}

@media screen and (min-width: 1920px) {
  .form-title {
    font-size: 25.31px;
  }

  .plan-name-ellipsis {
    width: 290px;
  }

  .close-form-icon,
  .form-pagination,
  .form-pagination-icon,
  .checked-form-pagination-icon,
  .form-section-title,
  .plan-name,
  .price,
  .plan-description,
  .internet-characteristics,
  .sd-channels,
  .hd-channels,
  .telephone,
  .promotion-tag,
  .promotion-name,
  .plan-detail {
    font-size: 17.71px;
  }

  .form-input-name,
  .form-input,
  .error-message,
  .searched-address,
  .form-textarea,
  .example-text,
  .form-action-button,
  .plan-tech,
  .plan-type,
  .promotion-discount-type,
  .promotion-discount,
  .address-name,
  .view-more-button,
  .switch-text {
    font-size: 15.18px;
  }

  .form-action-button {
    width: 106.31px;
    height: 43.03px;
  }

  .plan-price-container {
    width: 26%;
  }

  .plan-price {
    height: 54.41px;
  }

  .plan-description-container {
    width: 75%;
  }

  .installation-price,
  .promotion-type-text {
    font-size: 12.65px;
  }

  .promotion-tag {
    width: 154.40px;
  }

  .promotion-period,
  .promotion-description {
    font-size: 13.91px;
  }
}