.row {
  display: flex;
}

.column {
  display: flex;
  flex-direction: column;
}

.space-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

.align-center {
  align-items: center;
}

.section-title {
  font-size: 14px;
  color: #2b80ff;
  font-weight: bold;
}

.title {
  font-size: 14px;
  color: #5a607f;
  word-break: break-word;
}

@media screen and (min-width: 1280px) {

  .section-title,
  .title {
    font-size: 14px;
  }
}

@media screen and (min-width: 1366px) {

  .section-title,
  .title {
    font-size: 15.75px;
  }
}

@media screen and (min-width: 1920px) {

  .section-title,
  .title {
    font-size: 17.71px;
  }
}