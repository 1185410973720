.row {
  display: flex;
}

.column {
  display: flex;
  flex-direction: column;
}

.space-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

.align-center {
  align-items: center;
}

.sales-gallery-container {
  width: 100%;
  padding: 2em 0;
}

.subheader-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 31px 0 27px;
}

.subheader-title {
  color: #5a607f;
  font-size: 14px;
}

.add-image-button {
  height: 34px;
  background-color: #1d428a;
  border-radius: 3px;
  color: #ffffff;
  font-size: 12px;
  display: flex;
  align-items: center;
  font-weight: 400;
  border: none;
}

.add-image-button-icon {
  color: #ffffff;
}

.add-image-button:hover {
  background-color: #426da9;
  color: #ffffff !important;
  border: none;
}

.date-format {
  color: #2b80ff;
  font-weight: bold;
  font-size: 15px;
  text-align: center;
}

.user-format {
  color: #5a607f;
  font-size: 12px;
  text-align: center;
}

.image-container {
  width: 116px;
  height: 76px;
  background-color: #bababa;
  object-fit: contain;
  border-radius: 5px;
}

.image-download-icon {
  color: #2b80ff;
  margin-right: 12px;
}

.blue-text {
  color: #2b80ff;
  font-size: 12px;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
}

.blue-text:hover {
  opacity: 0.8;
}

.description-text {
  color: #5a607f;
  font-size: 12px;
}

.table-row-light {
  background-color: #ffffff;
}

.table-row-dark {
  background-color: #F7FAFC;
}

.caption {
  font-size: 12px;
  color: #5a607f;
  font-weight: bold;
}

@media screen and (min-width: 1280px) {
  .subheader-title {
    font-size: 14px;
  }

  .add-image-button,
  .user-format,
  .image-download-icon,
  .blue-text,
  .description-text,
  .caption {
    font-size: 12px;
  }

  .date-format {
    font-size: 15px;
  }

  .image-container {
    width: 116px;
    height: 76px;
  }
}

@media screen and (min-width: 1366px) {
  .subheader-title {
    font-size: 15.75px;
  }

  .add-image-button,
  .user-format,
  .image-download-icon,
  .blue-text,
  .description-text,
  .caption {
    font-size: 13.5px;
  }

  .date-format {
    font-size: 16.87px;
  }

  .image-container {
    width: 130.5px;
    height: 85.5px;
  }
}

@media screen and (min-width: 1920px) {
  .subheader-title {
    font-size: 17.71px;
  }

  .add-image-button,
  .user-format,
  .image-download-icon,
  .blue-text,
  .description-text,
  .caption {
    font-size: 15.18px;
  }

  .date-format {
    font-size: 18.97px;
  }

  .image-container {
    width: 146.81px;
    height: 96.18px;
  }
}